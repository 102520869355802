<template>
    <div class="vouchers-list">
            <v-data-table
                :page="page"
                :pageCount="numberOfPages"
                :search="search"
                :loading="loading"
                :headers="headers"
                :items-per-page="itemsPerPage"
                :server-items-length="totalVouchers"
                :options.sync="options"
                :items="items"
                mobile-breakpoint="0"
                class="elevation-1 black--text text-caption"
            >
                 <template v-slot:top>
                    <v-row>
                        <v-col cols="6">
                            <form v-on:submit.prevent="searchVoucher">
                                <v-text-field
                                    color="black"
                                    v-model="search"
                                    append-icon="search"
                                    placeholder="Search"
                                    class="mx-4 black--text text-body-2 px-5"
                                    @click:append="searchVoucher"
                                    clearable
                                    @click:clear="readDataFromAPI(1, itemsPerPage)"
                                ></v-text-field>
                            </form>
                        </v-col>
                    </v-row>
                </template>
                <template v-slot:[`item.voucher_id`]="{item}" >
                    <v-btn
                        link :to="'/voucher/'+ item._id"
                        text
                        small
                        class="blue--text text-decoration-underline"
                    >
                        {{ item.voucher_id }}
                    </v-btn>
                </template>
                <template v-slot:[`item.status`]="{item}">
                    <v-chip 
                        :color="getColor(item.status)" 
                        class="white--text"
                        small
                    >{{ item.status_label }}</v-chip>
                </template>
                <template v-slot:[`item.total`]="{item}">
                    <h4 class="green--text">
                        {{ item.total }}
                    </h4>
                </template>
            </v-data-table>
    </div>
</template>
<script>
import {get, post} from '../../helper/api';

export default {
  name: "VouchersList",
  data() {
    return {
      page: 1,
      totalVouchers: 0,
      numberOfPages: 0,
      itemsPerPage: 10,
      vouchers: [],
      loading: true,
      options: {},
      headers: [
        { text: "Invoice ID", value: "voucher_id" },
        { text: "Date", value: "date" },
        { text: "Sender", value: "sender_name" },
        { text: "Reciever", value: "reciever_name" },
        { text: "Status", value: "status"},
        { text: "Total (NTD)", value: 'total'},
      ],
      items: [],
      search: ''
    };
  },
  methods: {
        getColor: function (status){
            if(status) return 'green';
            return 'blue'
        },
        readDataFromAPI : function (page, itemsPerPage){
            let self = this;
            self.loading = true;

            // first get the total vouchers
            get('/vouchers/get/total').then(result => {

                let body = null;
                let total_vouchers = JSON.parse(result).count;
                self.totalVouchers = total_vouchers;
                let skip = total_vouchers - (itemsPerPage * page);

                if(itemsPerPage !== -1) {
                    body = {
                        skip: skip > 0 ? skip : 0,
                        limit: skip > 0 ? itemsPerPage : itemsPerPage + skip
                    }
                    post('/vouchers/limit', body).then(result => {
                        let voucher_data = JSON.parse(result);
                        let total_data = voucher_data.totalData;
                        self.items = total_data;
                        self.loading = false;
                    }).catch(err => {
                        console.log('Error : ' + err);
                    })
                }else{
                    get('/vouchers').then(result => {
                        let voucher_data =  JSON.parse(result);
                        let total_data = voucher_data;
                        self.items = total_data;
                        self.loading = false;
                    }).catch(err => {
                        console.log('Error : ' + err);
                    })
                }

            });
        },
        searchVoucher: function () {
            let self = this;
            this.loading = true;

            // if search is empty reset
            if(!this.search) {
                this.readDataFromAPI(1, this.itemsPerPage);
            } else {
                get('/vouchers/search/'+ this.search).then(result => {
                    self.items = JSON.parse(result);
                    self.totalVouchers = self.items.length;
                    self.loading = false;
                }).catch(error => {
                    console.log("Error : " + error);
                    self.loading = false;
                })
            }
        }
  },
  created() {
        this.readDataFromAPI(this.page, this.itemsPerPage);
  },
  watch: {
    options: function (newVal) {
        this.readDataFromAPI(newVal.page, newVal.itemsPerPage);
    },
    deep: true,
  },
};

</script>