import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: '#84e2ff',
                secondary: '#91bed6',
                black: '#666',
                blue: '#1ea2cd',
                green: '#27ae60',
                disabled: '#efefef',
                error: '#b71c1c',
                warning: '#ffe3a8',
                background: '#f9f9f9'
            }
        }
    }
});
