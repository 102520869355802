import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Vouchers from '../views/Vouchers';
import Voucher from '../views/Voucher';

import VoucherAdd from '../views/VoucherAdd';
import VoucherInfoEdit from '../views/VoucherInfoEdit';
import VouchersItemsEdit from '../views/VouchersItemsEdit';
import Report from '../views/Report';
import Login from '../views/Login';
import UserRoles from '../views/UserRoles';

import Promise from 'core-js-pure/features/promise';
import {get} from '../helper/api';
import store from '../store';

const verifyUser = function() {
  return new Promise(function(resolve, reject) {
    get('/users/verify').then(result => {
      resolve(JSON.parse(result));
    }).catch(() => {
      reject();
    });
  });
}

const verifyAdmin = function() {
  return new Promise(function(resolve, reject) {
    get('/users/verify').then(result => {
      let result_obj = JSON.parse(result);
      if(result_obj.role !== 0) {
        reject();
      }
      resolve(result_obj);
    }).catch(() => {
      reject();
    });
  });
}


Vue.use(VueRouter)
const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/',
    name: 'Dashboard',
    component: Home,
    beforeEnter: function (to, from, next) {
      verifyAdmin().then(user => {
        store.dispatch('SET_USER', user).then(() => next());
      }).catch(()=> next('/login'));
    }
  },
  {
    path: '/vouchers',
    name: 'Vouchers',
    component: Vouchers,
    beforeEnter: function (to, from, next) {
      verifyUser().then(user => {
        store.dispatch('SET_USER', user).then(() => next());
      }).catch(()=> next('/login'));
    }
  },
  {
    path: '/voucher-add',
    name: 'Voucher',
    component: VoucherAdd,
    beforeEnter: function (to, from, next) {
      verifyUser().then(user => {
        store.dispatch('SET_USER', user).then(() => next());
      }).catch(()=> next('/login'));
    }
  },
  {
    path: '/voucher/:id',
    name: 'Voucher',
    component: Voucher,
    beforeEnter: function (to, from, next) {
      verifyUser().then(user => {
        store.dispatch('SET_USER', user).then(() => next());
      }).catch(()=> next('/login'));
    }
  },
  {
    path: '/report',
    name: 'Report',
    component: Report,
    beforeEnter: function (to, from, next) {
      verifyAdmin().then(user => {
        store.dispatch('SET_USER', user).then(() => next());
      }).catch(()=> next('/login'));
    }
  },
  {
    path: '/voucher/edit-info/:id',
    name: 'Voucher Info Edit',
    component: VoucherInfoEdit,
    beforeEnter: function (to, from, next) {
      verifyUser().then(user => {
        store.dispatch('SET_USER', user).then(() => next());
      }).catch(()=> next('/login'));
    }
  },
  {
    path: '/voucher/edit-items/:id',
    name: 'Voucher Items Edit',
    component: VouchersItemsEdit,
    beforeEnter: function (to, from, next) {
      verifyUser().then(user => {
        store.dispatch('SET_USER', user).then(() => next());
      }).catch(()=> next('/login'));
    }
  },
  {
    path: '/user-roles',
    name: 'Users',
    component: UserRoles,
    beforeEnter: function (to, from, next) {
      verifyAdmin().then(user => {
        store.dispatch('SET_USER', user).then(() => next());
      }).catch(()=> next('/login'));
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
