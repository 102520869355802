<template>
    <div class="items-list">
        <div class="d-flex flex-row justify-space-between py-5 mb-5">
            <div class="font-weight-light text-h6">Toal Items : {{ items.length }}</div>
            <div class="font-weight-bold green--text text-h4">NTD {{ getTotal.toFixed(0) }} </div>
        </div>
        <div class="d-flex flex-row justify-end">
            <v-checkbox
                v-model="include_tax"
                label="Include Tax(5%)"
                color="green"
            >
            </v-checkbox>
        </div>
        <v-simple-table height="300px" fixed-header >
            <template v-slot:default >
            <thead>
                <tr>
                <th v-for="header in headers" class="text-left" :key="header.name">
                    {{header}}
                </th>
                </tr>
            </thead>
            <tbody>
                <tr
                v-for="(item, index) in items"
                :key="index"
                class="text-caption x-small black--text"
                >
                <td>
                    <v-chip small v-for="item_name in item.name" :key="item_name">
                        {{ item_name }}
                    </v-chip>
                </td>
                <td>{{ item.item_price }}</td>
                <td>{{ item.item_count }}</td>
                <td>{{ item.weight }}</td>
                <td>{{ item.price_per_count }}</td>
                <td>{{ getPrice(item).toFixed(0) }}</td>
                <td>
                    <v-btn
                        icon
                        small
                        color="black"
                        @click="$store.dispatch('REMOVE_ITEM', index)"
                    >
                        <v-icon>delete</v-icon>
                    </v-btn>
                </td>
                </tr>
                <tr>
                    <td v-for="(item, index) in form_data" :style=" index == 0 ? 'width: 20%' : ''" :key="index">
                        <v-select
                            v-if="item.type == 'select'"
                            :items="item.data"
                            :placeholder="item.placeholder"
                            v-model="item.value"
                            dense
                            :chips="item.key == 'name'"
                            class="text-body-2"
                            :multiple="item.key == 'name'"
                        >
                            <template v-slot:prepend>
                                 <v-btn
                                    v-if="item.key == 'name'"
                                    icon
                                    @click="manual_name_dialog = true"
                                >
                                    <v-icon>add</v-icon>
                                </v-btn>
                                <v-btn
                                    v-if="item.key == 'price_per_count'"
                                    icon
                                    @click="manual_val_dialog = true"
                                >
                                    <v-icon>add</v-icon>
                                </v-btn>
                            </template>
                        </v-select>
                        <v-text-field
                            v-else
                            color="black"
                            dense
                            :placeholder="item.placeholder"
                            v-model="item.value"
                            class="text-body-2"
                        ></v-text-field>
                    </td>
                    <td>
                        <v-btn
                            small
                            block
                            tile
                            flat
                            color="green"
                            dark
                            @click="addItem"
                        >
                            <v-icon>add</v-icon>
                        </v-btn>
                    </td>
                </tr>
            </tbody>
            </template>
        </v-simple-table>
        <!-- Manual Input Dialog -->
        <v-dialog
            v-model="manual_val_dialog"
            width="400"
        >
            <v-card>
                <v-card-title class="subtitle">
                    Enter Value
                </v-card-title>
                <v-card-text>
                        <v-text-field
                            color="black"
                            placeholder="Enter price"
                            v-model="manual_val"
                            class="text-body-2"
                        ></v-text-field>                
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        text
                        color="green"
                        @click="saveManualVal"                 
                    >
                        Save
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog
            v-model="manual_name_dialog"
            width="400"
        >
            <v-card>
                <v-card-title class="subtitle">
                    Enter Item Name
                </v-card-title>
                <v-card-text>
                        <v-text-field
                            color="black"
                            placeholder="Enter price"
                            v-model="manual_name"
                            class="text-body-2"
                        ></v-text-field>                
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        text
                        color="green"
                        @click="saveManualName"                 
                    >
                        Save
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- /Manual Input Dialog -->
    </div>
</template>
<script>
import item_data from '../../helper/item_data';

export default {
    name: 'ItemsList',
    data: ()=> ({
        selected: [],
        headers: ['物品名稱', '貨品總價格(NTD)', '件數', '重量(kg)', '單價(NTD)', '金額 (NTD)', ''],
        form_data: item_data,
        prices: [40, 50, 55, 60, 65, 70, 80, 100, 150, 200, 1200, 1500],
        manual_val_dialog: false,
        manual_name_dialog: false,
        manual_val: '',
        manual_name: '',
        include_tax: false
    }),
    methods: {
        getPrice(item) {
            return item.weight * item.price_per_count;
        },
        clearAll : function () {
            for(let i = 0; i < this.form_data.length ; i++) {
                this.form_data[i].value = '';
            }
        },
        addItem : function() {
            let item = {};
            this.form_data.map( data => {
                item[data.key] = data.value;
            });
            this.$store.dispatch('PUSH_ITEM', item);
            this.clearAll();
        },
        saveManualVal : function() {
            this.form_data[4].data.push(this.manual_val);
            this.manual_val_dialog = false;
        },
        saveManualName: function() {
            this.form_data[0].data.push(this.manual_name);
            this.manual_name_dialog = false;
        }
    },
    watch: {
        include_tax : function() {
            this.$store.dispatch('SET_TAX', this.include_tax);
        }
    },
    computed: {
        items() {
            return this.$store.state.items;
        },
        tax() {
            return this.$store.state.tax;
        },
        getTotal () {
            let total = 0;
            let tax = 5/100;
            this.items.map(item => {
                total += this.getPrice(item);
            });
            if(this.include_tax) {
                return total + (total * tax)
            } else {
                return total;
            }
        }
    }
}
</script>