import Promise from 'core-js-pure/features/promise';

const protocol = 'http://';
const host = 'pos.goldofhouse.com:3000/api';
// const host = 'localhost:3000/api';

const get = (endpoint) => {
    return new Promise((resolve, reject) => {
        var requestOptions = {
            method: 'GET',
            redirect: 'follow',
            credentials: 'include'
          };
          
          fetch(protocol + host + endpoint, requestOptions)
            .then(response => {
                if(response.status !== 200) {
                    reject(response);
                }
                return response.text();
            })
            .then(result => resolve(result))
            .catch(error => reject(error));
    });
}

const post = (endpoint, body) => {
    return new Promise((resolve, reject) => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

    
        var raw = JSON.stringify(body);
    
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow',
            credentials: 'include'
        };
        fetch(protocol + host + endpoint, requestOptions)
                .then(response => {
                    if(response.status !== 200) {
                        reject(response);
                    }
                    return response.text();
                })
                .then(result => resolve(result))
                .catch(error => reject(error));
    });
}

const remove = (endpoint) => {
    return new Promise((resolve, reject) => {
        var requestOptions = {
            method: 'DELETE',
            redirect: 'follow',
            credentials: 'include'
          };
          
          fetch(protocol + host + endpoint, requestOptions)
                . then(response => {
                    if(response.status !== 200) {
                        reject(response);
                    }
                    return response.text();
                })
                .then(result => resolve(result))
                .catch(error => reject(error));
    });
}

const put = (endpoint, body) => {
    return new Promise((resolve, reject) => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify(body);
        var requestOptions = {
            method: 'PUT',
            headers: myHeaders,
            body: raw,
            redirect: 'follow',
            credentials: 'include'
        };
        fetch(protocol + host + endpoint, requestOptions)
        .then(response => {
            if(response.status !== 200) {
                reject(response);
            }
            return response.text();
        })
        .then(result => resolve(result))
        .catch(error => reject(error));
    });
}

const patch = (endpoint, body) => {
    return new Promise((resolve, reject) => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify(body);

        var requestOptions = {
            method: 'PATCH',
            headers: myHeaders,
            body: raw,
            redirect: 'follow',
            credentials: 'include'
        };

        fetch(protocol + host + endpoint, requestOptions)
                .then(response => {
                    if(response.status !== 200) {
                        reject(response);
                    }
                    return response.text();
                })
                .then(result => resolve(result))
                .catch(error => reject(error));
    });
}

export {get, post, remove, put, patch};
