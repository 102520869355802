<template>
  <v-app>
    <app-bar :menus="menus" />
    <v-main class="background" >
      <router-view></router-view>
    </v-main>
  </v-app>
</template>
<style>
  .background {
      background: rgb(255,255,255);
      background: radial-gradient(circle, rgba(255,255,255,1) 0%, rgba(247,247,247,1) 46%, rgba(224,224,224,1) 100%);  
  }
  .gradient {
      background: rgb(166,243,255);
      background: linear-gradient(243deg, rgba(32,101,99,1) 0%, rgba(200,242,94,1) 46%, rgba(32,101,99,1) 100%); 
  }
</style>
<script>
import AppBar from './components/AppBar';
import menus from './helper/menu';

export default {
  name: 'App',
  components: {
    AppBar
  },
  data: () => ({
    menus
  }),
};

</script>
