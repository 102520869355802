<template>
    <div class="voucher-add">
        <v-container>
            <Header title="Add Voucher" />
            <form-generator/>
        </v-container>
    </div>
</template>
<script>
import Header from '../components/Header';
import FormGenerator from '../components/Vouchers/FormGenerator';

export default {
    name: 'VoucherAdd',
    components: {
        Header,
        FormGenerator
    }
}
</script>