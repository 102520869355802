<template>
    <div class="home-container">
        <v-container>
            <v-row
            no-gutters
            >
            <v-col
                cols="1"
                style="min-width: 100px; max-width: 100%;"
                class="flex-grow-1 flex-shrink-0"
            >
                <v-card
                class="pa-2"
                outlined
                tile
                >
                    <div 
                        class="text-md-subtitle-1 text-subtitle-2 font-weight-regular black--text"
                    > Overview Of This Month</div>
                    <bar-chart :chart_data="chartData"></bar-chart>
                <div>
                </div>
                </v-card>
            </v-col>
            <!-- <v-col
                cols="12" md="5" sm="12"
                style="min-width: 100px;"
                class="flex-grow-0"
            >
                <v-card
                class="pa-2"
                outlined
                tile
                >
                    <div 
                        class="text-md-subtitle-1 text-subtitle-2 font-weight-regular black--text"
                    >Overview By Categories </div>
                    <pie-chart :chart_data="getPieChartData"></pie-chart>
                </v-card>
            </v-col> -->
            </v-row>
        </v-container>
    </div>
</template>
<script>
import BarChart from './BarChart';
// import PieChart from './PieChart';
import {post} from '../../helper/api';

export default {
    name: 'HomeContainer',
    components: {
        BarChart,
        // PieChart,
    },
    data () {
        return {
            data: []
        }
    },
    methods: {
        getThisMonthData : function () {
            let now = new Date();
            let this_month = now.getMonth() + 1;
            let this_year = now.getFullYear();

            let body = {
                month: this_month, 
                year: this_year
            }

            post('/vouchers/monthly', body).then(result => {
                let json_result = JSON.parse(result);
                this.data = json_result;
            }).catch(err => {
                console.log(err);
            })        
        }
    },
    computed: {
        chartData: function() {
            if(this.data) {
                let chart_data = new Array(31).fill(0);
                this.data.map( data => {
                    chart_data[new Date(data.createdAt).getDate()] += parseInt(data.total);
                });
                return chart_data;
            } else {
                return [];
            }
        },
        getPieChartData: function() {
            if(this.data) {
                let pie_chart_data = {};
                this.data.map( data => {
                    data.items.map(item => {
                        let old = 0;
                        if(pie_chart_data[item.name]) {
                            old = pie_chart_data[item.name];
                        }
                        let new_val = old + 1;
                        pie_chart_data[item.name] = new_val
                    });
                });
                return pie_chart_data;
            } else {
                return [];
            }
        }
    },
    created () {
        this.getThisMonthData();
    }
}
</script>