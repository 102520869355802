<template>
    <div class="report">
        <v-container>
            <v-row no-gutters>
                <v-col cols="12">
                    <v-card
                        tile
                        outlined
                    >
                        <v-row>
                            <v-col cols="12" md="6"
                                class="d-flex justify-center"
                            >
                                <overview-card 
                                    icon="description"
                                    :value="totalVouchers"
                                    label="Vouchers"
                                    color="blue"
                                    class="flex-grow-1"
                                />
                                <overview-card 
                                    icon="inventory_2"
                                    :value="totalItems"
                                    label="Packages"
                                    color="orange darken-2"
                                    class="flex-grow-1"
                                />
                                <overview-card 
                                    icon="search"
                                    :value="totalPrice"
                                    label="Total(NTD)"
                                    color="green"
                                    class="flex-grow-1"
                                />
                            </v-col>
                            <v-col 
                                cols="12" md="6"
                                class="d-flex justify-center align-center"
                            >                        
                                <v-overflow-btn
                                    dense
                                    label="select month"
                                    v-model="month"
                                    :items="months"
                                    hint="select month"
                                    color="black"
                                    active-class="black"
                                    item-color="black"
                                    class="text-body-2"
                                >

                                </v-overflow-btn>
                                <v-overflow-btn
                                    dense
                                    label="select year"
                                    v-model="year"
                                    :items="getYears"
                                    hint="select year"
                                    color="black"
                                    active-class="black"
                                    item-color="black"
                                    class="text-body-2"
                                >

                                </v-overflow-btn>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6">
                    <v-card tile outlined class="pa-5">
                        <bar-chart :chart_data="chartData"></bar-chart>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6">
                    <v-card tile outlined class="pa-13" align="right">
                        <v-btn
                            tile
                            dark
                            color="green"
                            @click="exportData"
                        >
                            export data (.csv)
                        </v-btn>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>
<script>
import OverviewCard from '../components/Report/OverviewCard';
import BarChart from '../components/Home/BarChart';
import { ExportToCsv } from 'export-to-csv';
import { post } from '../helper/api';

export default {
    name: 'Report',
    components: {OverviewCard, BarChart},
    data () {
        return {
            month: null,
            year: new Date().getFullYear(),
            months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
            data: null,
            export_data: this.getExportData(),
            payment_methods: [
                'လက်ငင်းရှင်း',
                'ဘဏ်လွှဲ',
                'ပစ္စည်းရောက်မှ ရန်ကုန်ရှင်း ',
                'မြို့တွင်းကားခ ရန်ကုန်ရှင်း'
            ],
            yangon_service: [
                'လာယူ',
                'ရန်ကုန်မြို့တွင်း အိမ်အောက် ',
                'နယ်ကားဂိတ်ပို့ ',
                'နယ်အရောက်ပို့'
            ]
        }
    },
    watch: {
        month (newVal) {
            if(this.month && this.year) {
                this.getDataForTheMonth(this.getIndexOfMonth(newVal), this.year);
            }
        },
        year (newVal) {
            if(this.month && this.year) {
                this.getDataForTheMonth(this.month, newVal);
            }
        }
    },
    computed: {
        getYears : function () {
            let startFrom = 2021;
            let currentYear = new Date().getFullYear();
            let years = [];
            do {

                years.push(startFrom);
                startFrom++;

            } while(startFrom <= currentYear);
            return years;
        },
        totalVouchers: function() {
            if(this.data) {
                return this.data.length;
            } else {
                return 0;
            }
        },
        totalItems: function() {
            if(this.data) {
                let total_items = 0;
                this.data.map( data => {
                    data.items.map( item => {
                        total_items += parseInt(item.item_count)
                    });
                });
                return total_items;
            } else {
                return 0;
            }
        },
        totalPrice: function() {
            if(this.data) {
                let total = 0;
                this.data.map( data => {
                    total += parseInt(data.total);
                });
                return total;
            } else {
                return 0;
            }
        },
        chartData: function() {
            if(this.data) {
                let chart_data = new Array(31).fill(0);
                this.data.map( data => {
                    chart_data[new Date(data.createdAt).getDate()] += parseInt(data.total);
                });
                return chart_data;
            } else {
                return [];
            }
        },
        getMonth: function() {
            if(this.month) {
                return this.month;
            } else {
                let now = new Date();
                let month = now.getMonth();
                return this.months[month];
            }
        }
    },
    methods: {
        getItemsCount: function(data) {
            let total_count = 0;
            data.items.map(item => {
                total_count += parseInt(item.item_count);
            });
            return total_count;
        },
        getPrice: function(data) {
            let string_to_return = '';
            data.items.map((item, index) => {
                if(index < data.items.length) {
                    string_to_return += item.price_per_count + ','
                } else {
                    string_to_return += item.price_per_count
                }
            });
            return string_to_return;
        },
        getExportData: function () {
            if(this.data) {
                let export_data = [];
                this.data.map(data => {

                    export_data.push({
                        Date: data.date,
                        No: data.voucher_id,
                        Sender: data.sender_name,
                        Sender_Phone: data.sender_phone,
                        Reciever: data.reciever_name,
                        Reciever_Phone: data.reciever_phone,
                        Reciever_Address: data.reciever_address,
                        Qua: this.getItemsCount(data),
                        Kg: data.total_weight,
                        Price: this.getPrice(data),
                        CarFee: data.car_fee_val,
                        Total: data.total,
                        Remark: this.payment_methods[data.payment_method] + ',' + this.yangon_service[data.yangon_service],
                        Remark1: data.remark_1,
                        Remark2: data.remark_2
                    });

                });
                return export_data;
            } else {
                return []
            }
        },
        getDataForTheMonth : function (month, year) {
            let body = {
                month: parseInt(month),
                year: parseInt(year)
            }

            post('/vouchers/monthly', body).then(result => {
                let json_result = JSON.parse(result);
                this.data = json_result;
            }).catch(err => {
                console.log(err);
            })

        },
        getIndexOfMonth: function (monthname) {
            return this.months.indexOf(monthname) + 1;
        },
        exportData: function () {
            const options = { 
                fieldSeparator: ',',
                quoteStrings: '"',
                decimalSeparator: '.',
                showLabels: true, 
                showTitle: true,
                title: this.getMonth + '-' + this.year + '( Report )',
                useTextFile: false,
                useBom: true,
                useKeysAsHeaders: true,
                filename: this.getMonth + '-' + this.year + '( Report )'
                // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
            };
            const csvExporter = new ExportToCsv(options);
            csvExporter.generateCsv(this.getExportData());
        }
    },
    created () {
        // get this month's list 
        let now = new Date();
        let month = now.getMonth() + 1;
        let year = now.getFullYear();
        this.getDataForTheMonth(month, year);
    },
}
</script>