<template>
    <div class="user-roles">
        <v-container>
            <v-card align="right" flat>
                <v-btn
                    depressed
                    light
                    tile
                    color="green"
                    dark
                    @click="add_dialog = true"
                >
                    <v-icon>add</v-icon>
                    Add New User
                </v-btn>
            </v-card>
                    <v-card tile outlined class="pa-13 d-flex flex-wrap justify-space-around">
                        <user-card
                            v-for="user in users"
                            :key="user._id"
                            :user = user
                        ></user-card>
                    </v-card>
        </v-container>

        <v-dialog
            v-model="add_dialog"
            width="400"
        >
            <v-card
                tile
                outlined
                class="pa-5"
            >
                <v-card-title>
                    <h3 class="text-subtitle-2">
                        Add New User
                    </h3>
                </v-card-title>
                <v-divider class="py-3"></v-divider>
                <v-card-text>
                    <v-text-field
                        v-model="newuser_email"
                        label="Email"
                        class="text-body-2 px-8"
                        color="black"
                        dense
                        outlined
                    >
                    </v-text-field>
                    <v-text-field
                        v-model="newuser_password"
                        label="Password"
                        type="password"
                        class="text-body-2 px-8"
                        color="black"
                        dense
                        outlined
                    >
                    </v-text-field>
                    <v-text-field
                        v-model="newuser_name"
                        label="Full Name"
                        class="text-body-2 px-8"
                        color="black"
                        dense
                        outlined
                    >
                    </v-text-field>
                    <v-radio-group v-model="newuser_role">
                        <v-radio
                            v-for="role in roles"
                            :key="role.name"
                            :label="role.name"
                            color="black"
                        >

                        </v-radio>
                    </v-radio-group>
                </v-card-text>
                <div class="d-flex">
                    <v-spacer></v-spacer>
                    <v-btn
                        tile
                        depressed
                        color="grey"
                        dark
                        class="mx-1"
                        @click="add_dialog = false"
                    >
                        cancel
                    </v-btn>
                    <v-btn
                        tile
                        depressed
                        color="green"
                        dark
                        @click="addNewUser"
                    >
                        save
                    </v-btn>
                </div>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import UserCard from '../components/UserRoles/UserCard';
import { get, post } from '../helper/api';

export default {
    name: 'UserRoles',
    components: { UserCard },
    data () {
        return {
            users: [],
            add_dialog: false,
            roles: [
                {
                    name: 'Admin',
                    value: 0
                },
                {
                    name: 'Accoutant',
                    value: 1
                }
            ],
            newuser_name: '',
            newuser_password: '',
            newuser_email: '',
            newuser_role: 1
        }
    },
    methods: {
        addNewUser: function () {
            let new_user = {
                full_name: this.newuser_name,
                email: this.newuser_email,
                password: this.newuser_password,
                role: this.newuser_role
            }
            post('/users', new_user).then(() => {
                this.newuser_name = '';
                this.newuser_password = '';
                this.newuser_email = '';
                this.newuser_role = 1;
                this.add_dialog = false;
                this.getAllUsers();
            }).catch(err => {
                console.log("Error: " + err);
            })
        },
        getAllUsers : function () {
            get('/users').then( result => {
                let users = JSON.parse(result);
                this.users = users;
            }).catch(err => {
                console.log("Error: " + err);
            })
        }
    },
    created () {
        this.getAllUsers();
    }
}
</script>