<template>
    <div class="form-generator">
        <v-container>
            <!-- Save -->
            <v-card align="right" flat>
                <v-btn
                    depressed
                    light
                    tile
                    color="green"
                    dark
                    @click="saveVoucher"
                >
                    <v-icon>save</v-icon>
                    Save
                </v-btn>
            </v-card>
            <!-- /Save -->
            <v-row
            no-gutters
            >   
                <!-- VoucherId -->
                <v-col cols="6">
                    <v-card
                        tile
                        outlined
                        class="pa-8"
                    >
                        <v-text-field
                            dense
                            label="Voucher Id"
                            color="black"
                            placeholder="Auto generate avaliable"
                            class="black--text text-body-2"
                            append-icon="refresh"
                            v-model="form_data.voucher_id"
                        >
                            <template v-slot:append>
                                <v-btn
                                    icon
                                    @click="getVoucherId"
                                >
                                    <v-icon>autorenew</v-icon>
                                    </v-btn>
                                </template>
                        </v-text-field>
                    </v-card>
                </v-col>
                <!-- /VoucherId -->
                <!-- Date -->
                <v-col cols="6">
                    <v-card
                        tile
                        outlined
                        class="pa-8"
                    >
                        <v-text-field
                            dense
                            label="Date"
                            color="black"
                            placeholder="Auto generate avaliable"
                            append-icon="refresh"
                            class="black--text text-body-2"
                            v-model="form_data.date"
                        >
                            <template v-slot:append>
                                <v-btn
                                    icon
                                    @click="generateDate"
                                >
                                    <v-icon>autorenew</v-icon>
                                    </v-btn>
                                </template>
                        </v-text-field>
                    </v-card>
                </v-col>
                <!-- /Date -->
                <!-- Sender -->
                <v-col cols="12" sm="4">
                    <v-card
                        tile
                        class="pa-8"
                        outlined
                        height="488"
                    >
                        <div class="d-flex flex-row">
                            <div 
                                class="text-md-subtitle-1 text-subtitle-2 font-weight-bold black--text"
                            > Sender </div>
                            <v-spacer></v-spacer>
                            <v-btn
                                small
                                color="blue"
                                dark
                                tile
                                @click="newcustomer_dialog = true; newcustomer_type = 'sender'"
                            >
                                new
                            </v-btn>
                        </div>
                        <v-card-text>
                        <v-autocomplete
                            dense
                            v-model="sender"
                            :items="items"
                            :loading="isLoading"
                            :search-input.sync="send_search"
                            :filter="customerFilter"
                            class="text-body-2"
                            color="black"
                            hide-no-data
                            hide-selected
                            item-text="name"
                            item-value="API"
                            label="Search In Database"
                            placeholder="Start typing to Search"
                            prepend-icon="mdi-database-search"
                            return-object
                        ></v-autocomplete>
                        </v-card-text>
                        <v-expand-transition>
                        <v-list
                            class="grey lighten-3"
                            v-if="sender"
                        >
                            <v-list-item>
                             <v-list-item-content>
                                <p class="text-body-2 black--text"> <v-icon>person</v-icon>  {{sender.name}} </p>
                            </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                            <v-list-item-content>
                                <p class="text-body-2 black--text"> <v-icon>map</v-icon> {{sender.address}} </p>
                            </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                            <v-list-item-content>
                                <p class="text-body-2 black--text"> <v-icon>phone</v-icon> {{sender.phone}} </p>
                            </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                                <v-btn
                                    x-small
                                    text
                                    depressed
                                    color="red"
                                    @click="delete_customer(sender._id, 'sender')"
                                >
                                    delete
                                </v-btn>
                                <v-btn
                                    x-small
                                    text
                                    depressed
                                    color="blue"
                                    @click="edit_customer(sender)"
                                >
                                    Edit
                                </v-btn>
                            </v-list-item>
                            
                        </v-list>
                        </v-expand-transition>
                    </v-card>
                </v-col>
                <!-- /Sender -->
                <!-- Reciever -->
                <v-col cols="12" sm="4">
                    <v-card
                        tile
                        class="pa-8"
                        outlined
                        height="488"
                    >
                        <div class="d-flex flex-row">
                            <div 
                                class="text-md-subtitle-1 text-subtitle-2 font-weight-bold black--text"
                            > Reciever </div>
                            <v-spacer></v-spacer>
                            <v-btn
                                small
                                color="blue"
                                dark
                                tile
                                @click="newcustomer_dialog = true; newcustomer_type = 'reciever'"
                            >
                                new
                            </v-btn>
                        </div>
                        <v-card-text>
                        <v-autocomplete
                            dense
                            v-model="reciever"
                            :items="items"
                            :loading="isLoading"
                            :search-input.sync="recieve_search"
                            :filter="customerFilter"
                            class="text-body-2"
                            color="black"
                            hide-no-data
                            hide-selected
                            item-text="name"
                            item-value="API"
                            label="Search In Database"
                            placeholder="Start typing to Search"
                            prepend-icon="mdi-database-search"
                            return-object
                        ></v-autocomplete>
                        </v-card-text>
                        <v-expand-transition>
                        <v-list
                            class="grey lighten-3"
                            v-if="reciever"
                        >
                            <v-list-item>
                            <v-list-item-content>
                                <p class="text-body-2 black--text"> <v-icon>person</v-icon>  {{reciever.name}} </p>
                            </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                            <v-list-item-content>
                                <p class="text-body-2 black--text"> <v-icon>map</v-icon> {{reciever.address}} </p>
                            </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                            <v-list-item-content>
                                <p class="text-body-2 black--text"> <v-icon>phone</v-icon> {{reciever.phone}} </p>
                            </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                                <v-btn
                                    x-small
                                    text
                                    depressed
                                    color="red"
                                    @click="delete_customer(reciever._id, 'reciever')"
                                >
                                    delete
                                </v-btn>
                                <v-btn
                                    x-small
                                    text
                                    depressed
                                    color="blue"
                                    @click="edit_customer(reciever)"
                                >
                                    Edit
                                </v-btn>
                            </v-list-item>
                        </v-list>
                        </v-expand-transition>
                    </v-card>               
                </v-col>
                <!-- /Reciever -->
                <!-- Remark -->
                <v-col cols="12" sm="4">
                    <v-card
                        tile
                        class="pa-8"
                        outlined
                        height="488"
                    >
                        <div 
                            class="text-md-subtitle-1 text-subtitle-2 font-weight-bold black--text"
                        > Remarks </div>
                        <v-textarea
                            outlined
                            placeholder="Enterr remark 1"
                            label="remark 1"
                            color="black"
                            class="my-7"
                            height="100"
                            v-model="form_data.remark_1"
                        />
                        <v-textarea
                            outlined
                            placeholder="Enterr remark 2"
                            label="remark 2"
                            color="black"
                            class="my-7"
                            height="100"
                            v-model="form_data.remark_2"
                        />
                    </v-card>
                </v-col>
                <!-- /Remark -->
                <!-- Options -->
                <v-col cols="12" sm="4"
                    v-for="(option, index) in options" :key="index"
                >
                    <v-card
                        tile
                        class="pa-8"
                        outlined
                        height="288"
                    >
                        <div 
                            class="text-md-subtitle-1 text-subtitle-2 font-weight-bold black--text"
                        > {{ option.name }} </div>
                        <v-radio-group class="text-caption" v-model="form_data[option.key]">
                                <v-radio 
                                    v-for="option_item in option.data" 
                                    :key=option_item.name
                                    :label="option_item.name"
                                    :value="option_item.value"
                                    color="green"
                                >
                                </v-radio>
                        </v-radio-group>
                        <v-text-field
                            v-if="option.key == 'car_fee'"
                            color="black"
                            placeholder="Enter Carfee"
                            v-model="form_data.car_fee_val"
                            label="Car Fee Amount"
                        />
                    </v-card>
                </v-col>
                <!-- /Options -->
                <!-- Item list -->
                <v-col cols="12">
                    <v-card
                        tile
                        class="pa-8"
                        outlined
                    >
                        <item-list></item-list>
                    </v-card>
                </v-col>
                <!-- /Item list -->
            </v-row>
        </v-container>
        <!-- customer dialog -->
        <v-dialog
            v-model="newcustomer_dialog"
            width="400"
        >
            <v-card
                tile
                outlined
                class="pa-5"
            >
                <v-card-title>
                    <h3 class="text-subtitle-2">
                        {{ edit_dialog ? 'Edit Customer' : 'New Customer' }}
                    </h3>
                </v-card-title>
                <v-divider class="py-3"></v-divider>
                <v-card-text>
                    <v-text-field
                        v-model="newcustomer_name"
                        label="Name"
                        class="text-body-2 px-8"
                        color="black"
                        dense
                        outlined
                    >
                    </v-text-field>
                    <v-textarea
                        v-model="newcustomer_address"
                        label="Address"
                        class="text-body-2 px-8"
                        color="black"
                        dense
                        outlined
                        height="100"
                    >
                    </v-textarea>
                    <v-text-field
                        v-model="newcustomer_ph"
                        label="Phone"
                        class="text-body-2 px-8"
                        color="black"
                        dense
                        outlined
                    >
                    </v-text-field>
                </v-card-text>
                <div class="d-flex">
                    <v-spacer></v-spacer>
                    <v-btn
                        tile
                        depressed
                        color="grey"
                        dark
                        class="mx-1"
                        @click="newcustomer_dialog = false"
                    >
                        cancel
                    </v-btn>
                    <v-btn
                        tile
                        depressed
                        color="green"
                        dark
                        @click="edit_dialog ? updateCustomer() : saveNewCustomer()"
                    >
                        save
                    </v-btn>
                </div>
            </v-card>
        </v-dialog>
        <!-- /customer dialog -->
    </div>
</template>
<script>
// import form_data from '../../helper/form_data';
import {get, post, remove, put} from '../../helper/api';
import ItemList from './ItemsList';

export default {
    name: 'FormGenerator',
    components: {
        ItemList
    },
    data () {
        return {
            form_data: {
                voucher_id: 0,
                date: '',
                sender_name: '',
                sender_address: '',
                sender_phone: '',
                reciever_name: '',
                reciever_address: '',
                reciever_phone: '',
                remark_1: '',
                remark_2: '',
                payment_method: 0,
                yangon_service: 0,
                car_fee: 0,
                car_fee_val: 0
            },
            newcustomer_dialog: false,
            newcustomer_id: '',
            newcustomer_name: '',
            newcustomer_address: '',
            newcustomer_ph: '',
            newcustomer_type: '',
            voucher_id: 0,
            update_id: '6070552d24eb2f41b0274369',
            voucher_id_api: {},
            options: [
                {
                    name: 'Payment Methods',
                    value: null,
                    key: 'payment_method',
                    data: [
                        {
                            name: '現金支付',
                            value: 0,
                        },
                        {
                            name: '銀行轉帳',
                            value: 1
                        },
                        {
                            name: '貨到付款(在仰光付)',
                            value: 2
                        },
                        {
                            name: '送府車費(在仰光付)',
                            value: 3
                        }
                    ]
                },
                {
                    name: 'Yangon Service',
                    value: null,
                    key: 'yangon_service',
                    data: [
                        {
                            name: '自取',
                            value: 0,
                        },
                        {
                            name: '送到府摟下(仰光)',
                            value: 1
                        },
                        {
                            name: '送到仰光車站之車費',
                            value: 2
                        },
                        {
                            name: '送到府(各城市)',
                            value: 3
                        }
                    ]
                },
                {
                    name: 'Car Fee',
                    value: null,
                    key: 'car_fee',
                    data: [
                        {
                            name: '送到府摟下(仰光)',
                            value: 0
                        },
                        {
                            name: '送到仰光車站之車費',
                            value: 1
                        },
                        {
                            name: '送到府(各城市)',
                            value: 2
                        }
                    ]
                }
            ],
            descriptionLimit: 60,
            isLoading: false,
            sender: null,
            reciever: null,
            send_search: null,
            recieve_search: null,
            entries: [],
            edit_dialog: false
        }
    },
    methods: {
        customerFilter (customer, search_text) {
            const name = customer.name.toLowerCase();
            const phone = customer.phone;
            const query_text = search_text.toLowerCase();

            return name.indexOf(query_text) > -1 || phone.indexOf(query_text) > -1;
        },
        edit_customer (customer) {
            this.edit_dialog = true;
            this.newcustomer_dialog = true;
            this.newcustomer_id = customer._id;
            this.newcustomer_name = customer.name;
            this.newcustomer_address = customer.address;
            this.newcustomer_ph = customer.phone;
        },
        delete_customer (id, type) {
            let self = this;
            remove('/customers/'+id).then(() => {
                if(type == 'sender') {
                    this.sender = null
                } else {
                    this.reciever = null
                }
                get('/customers').then(result => {
                    const {count, entries} = JSON.parse(result);
                    self.count = count;
                    self.entries = entries;
                    self.isLoading = false;
                }).catch(err => {
                    console.log("Error : " + JSON.stringify(err));
                })
            }).catch(err => {
                console.log("Error : " + err);
            })
        },
        updateCustomer () {
            let body = {
                name: this.newcustomer_name,
                address: this.newcustomer_address,
                phone: this.newcustomer_ph
            }
            put('/customers/'+ this.newcustomer_id, body).then(() => {
                this.clearNewCustomer();
                this.newcustomer_dialog = false;
                this.edit_dialog = false;
                this.getCustomersForce();
            }).catch(err => {
                console.log("Error : " + JSON.stringify(err));
            })
        },
        getPrice(item) {
            return item.weight * item.price_per_count;
        },
        getTotal () {
            let total = 0;
            let tax = 5/100;
            this.deliver_items.map(item => {
                total += this.getPrice(item);
            });
            if(this.tax) {
                return total + (total * tax) + parseInt(this.form_data.car_fee_val);
            } else {
                return total + parseInt(this.form_data.car_fee_val);
            }
        },
        getTotalWeight() {
            let total_weight = 0;
            this.deliver_items.map(item => {
                total_weight += parseFloat(item.weight);
            });
            return total_weight;
        },
        getCustomersForce() {
           let self = this;
           this.isLoading = true;
            get('/customers').then(result => {
                const {count, entries} = JSON.parse(result);
                self.count = count;
                self.entries = entries;
                self.isLoading = false;
                this.sender = null;
                this.reciever = null;
            }).catch(err => {
                console.log("Error : " + JSON.stringify(err));
            })
        },
        getCustomers () {
            let self = this;

            // Items have already been loaded
            if (this.items.length > 0) return

            // Items have already been requested
            if (this.isLoading) return

            this.isLoading = true;
            get('/customers').then(result => {
                const {count, entries} = JSON.parse(result);
                self.count = count;
                self.entries = entries;
                self.isLoading = false;
            }).catch(err => {
                console.log("Error : " + JSON.stringify(err));
            })
        },
        clearNewCustomer() {
            this.newcustomer_name = '';
            this.newcustomer_address = '';
            this.newcustomer_ph = '';
        },
        saveNewCustomer () {
            // save to db 
            let self = this;
            let body = {
                name: this.newcustomer_name,
                address: this.newcustomer_address,
                phone: this.newcustomer_ph
            }

            post('/customers', body).then(() => {
                get('/customers').then(result => {
                    const {count, entries} = JSON.parse(result);
                    self.count = count;
                    self.entries = entries;
                    self.isLoading = false;
                }).catch(err => {
                    console.log("Error : " + JSON.stringify(err));
                })
            }).catch(err => console.log("Error" + err));

            if(this.newcustomer_type == 'sender') {
                this.sender = {
                    name: this.newcustomer_name,
                    address: this.newcustomer_address,
                    phone: this.newcustomer_ph
                }
            } else {
                this.reciever = {
                    name: this.newcustomer_name,
                    address: this.newcustomer_address,
                    phone: this.newcustomer_ph
                }
            }
            this.clearNewCustomer();
            this.newcustomer_dialog = false;
        },
        getVoucherId () {
            get('/vouchers/voucher_id/get').then( result => {
                let {_id, code, vouchers, updated_month} = JSON.parse(result);
                let thisMonth = new Date().getMonth();
                let voucher_id = null;
                if(thisMonth === updated_month) {
                    voucher_id = code + vouchers + 1;
                    this.form_data.voucher_id = voucher_id;
                    this.voucher_id_api = {
                        _id,
                        code,
                        vouchers,
                        updated_month
                    }
                } else {
                    voucher_id = code + 10000 + 1;
                    this.form_data.voucher_id = voucher_id;
                    this.voucher_id_api = {
                        _id,
                        code: code + 10000,
                        vouchers: 1,
                        updated_month: thisMonth
                    }
                }
            });
        },
        getDate() {
            let date = new Date();
            let day = date.getDate() > 9 ? date.getDate() : '0' + date.getDate() ;
            let month = date.getMonth() + 1 > 9 ? date.getMonth() + 1 : '0' + (date.getMonth() + 1);
            let year = date.getFullYear();
            return year+'.'+month+'.'+day;
        },
        generateDate() {
            this.form_data.date = this.getDate();
        },
        saveVoucher() {
            let self = this;
            let data_to_update = {
                status: 0,
                items: this.deliver_items,
                total: this.getTotal().toFixed(0),
                voucher_id: this.form_data.voucher_id,
                date: this.form_data.date,
                payment_method: this.form_data.payment_method,
                yangon_service: this.form_data.yangon_service,
                car_fee: this.form_data.car_fee,
                car_fee_val: this.form_data.car_fee_val,
                sender_name: this.sender ? this.sender.name : 'No Name',
                sender_address: this.sender ? this.sender.address : 'No Address',
                sender_phone: this.sender ? this.sender.phone : 'No Phone',
                reciever_name: this.reciever ? this.reciever.name : 'No Name',
                reciever_address: this.reciever ? this.reciever.address : 'No Address',
                reciever_phone: this.reciever ? this.reciever.phone : 'No Phone',
                remark_1: this.form_data.remark_1,
                remark_2: this.form_data.remark_2,
                tax: this.tax,
                total_weight: this.getTotalWeight()
            }

            // for voucher id 
            let data_for_voucherId = {
                code: this.voucher_id_api.code,
                vouchers: this.voucher_id_api.vouchers + 1,
                updated_month: new Date().getMonth()
            }

            post('/vouchers/', data_to_update).then(()=> {
                post('/vouchers/id/'+this.voucher_id_api._id, data_for_voucherId).then(()=> {
                    self.$router.push('/vouchers');
                    self.$store.dispatch('CLEAR_ITEMS');
                    self.$store.dispatch('SET_TAX', false);
                }).catch(err => {
                    alert("What went wrong ? :" + JSON.stringify(err));
                });
            }).catch(() => {
                alert("What went wrong ? Must contain at least one item . Press F12 and click Console for more detail . ")
            });
        },
    },
    created() {
        this.getVoucherId();
        this.generateDate();
    },
    computed: {
      sender_fields () {
        if (!this.sender) return []

        return Object.keys(this.sender).map(key => {
          return {
            key,
            value: this.sender[key] || 'n/a',
          }
        })
      },
      reciever_fields () {
        if (!this.reciever) return []

        return Object.keys(this.reciever).map(key => {
          return {
            key,
            value: this.reciever[key] || 'n/a',
          }
        })
      },
      items () {
        return this.entries.map(entry => {
          const name = entry.name.length > this.nameLimit
            ? entry.name.slice(0, this.nameLimit) + '...'
            : entry.name

          return Object.assign({}, entry, { name })
        })
      },
      deliver_items () {
          return this.$store.state.items;
      },
      tax() {
          return this.$store.state.tax;
      }
    },

    watch: {
      send_search () {
        this.getCustomers();
      },
      recieve_search () {
        this.getCustomers();
      }
    },
}
</script>