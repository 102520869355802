<template>
    <div class="print-div">
            <v-card flat align="right">
                <v-btn
                    depressed
                    light
                    tile
                    color="red lighten-1"
                    dark
                    @click="alert = true"
                >
                    <v-icon>delete</v-icon>
                    Delete
                </v-btn>
                <v-btn
                    depressed
                    light
                    tile
                    color="orange lighten-1"
                    dark
                    @click="$router.push('/voucher/edit-info/'+voucherData._id)"
                >
                    <v-icon>edit</v-icon>
                    Edit Info
                </v-btn>
                <v-btn
                    depressed
                    light
                    tile
                    color="orange"
                    dark
                    @click="$router.push('/voucher/edit-items/'+voucherData._id)"
                >
                    <v-icon>edit</v-icon>
                    Edit Items
                </v-btn>
                <v-btn
                    depressed
                    light
                    tile
                    :color="voucherData.status == 0 ? 'green' :'blue'"
                    dark
                    @click="changeStatus"
                >
                    <div v-if="voucherData.status == 0">
                        <v-icon>check</v-icon>
                        Mark as delivered
                    </div>
                    <div v-else>
                        <v-icon>pending_actions</v-icon>
                        Mark as shipping
                    </div>
                </v-btn>
            </v-card>
        <v-row no-gutters>
            <v-col
                cols="1"
                style="min-width: 100px; max-width: 100%;"
                class="flex-grow-1 flex-shrink-0"            
            >
                <v-card
                    class="pa-10"
                    outlined
                    tile
                    id="to-print"
                >
                    <div class="print-main-header-container">
                        <h1 class="form_header">
                            {{form_data.tw_company_name}}
                        </h1>
                    </div>
                    <div class="print-secondary-header-container">
                            <img src="../../assets/printlogo1.png" alt="logo1" class="print-logo">
                            <div class="print-secondary-inner">
                                <h4 class="form_contact">
                                    {{ print_data[current_lang].taiwan_address }}
                                </h4>
                                <h1 class="form_header_secondary">
                                    {{form_data.mm_company_name}}
                                </h1>
                                <h4 class="form_contact">
                                    {{ form_data.mm_company_contact }}
                                </h4>
                            </div>
                            <img src="../../assets/printlogo2.png" alt="logo1" class="print-logo">
                    </div>
                    <table class="date-id-container">
                        <tr>
                            <td>
                                {{ print_data[current_lang].date }} : {{ voucherData.date }}
                            </td>
                            <td>
                                {{ print_data[current_lang].voucher_id }} : {{ voucherData.voucher_id }}
                            </td>
                        </tr>
                    </table>
                    <table class="main-table">
                        <!-- row A -->
                        <tr>
                            <td style="width: 50%" colspan="2" class="border"> {{print_data[current_lang].sender}} : {{ voucherData.sender_name }}</td>
                            <td colspan="4" class="border"> {{ print_data[current_lang].sign }} :  </td>
                        </tr>
                        <!-- row A -->
                        <tr>
                            <td rowspan="4" colspan="2" class="border baseline"> 
                                {{ print_data[current_lang].address }} : {{ voucherData.sender_address }}
                                <br>
                                <br>
                                {{ print_data[current_lang].phone }} : {{ voucherData.sender_phone }}
                            </td>
                            <td rowspan="4" colspan="2" class="border"> {{ print_data[current_lang].payment_method }}  </td>
                            <td class="border" align="center">
                                {{ voucherData.payment_method == 0 ? '✔' : '' }}
                            </td>
                            <td style="width: 25%;" class="border"> {{ print_data[current_lang].instant_in_cash }} </td>
                        </tr>
                        <!-- row A -->
                       <tr>
                           <td class="border" align="center">
                                {{ voucherData.payment_method == 1 ? '✔' : '' }}
                            </td>
                            <td style="width: 25%" class="border"> {{ print_data[current_lang].via_bank }} </td>
                        </tr>
                        <!-- row A -->
                        <tr>
                            <td class="border" align="center">
                                {{ voucherData.payment_method == 2 ? '✔' : '' }}
                            </td>
                            <td style="width: 25%" class="border"> {{ print_data[current_lang].cash_on_delivery }} </td>
                        </tr>
                        <!-- row A -->
                        <tr>
                            <td class="border" align="center">
                                {{ voucherData.payment_method == 3 ? '✔' : '' }}
                            </td>
                            <td class="border"> {{ print_data[current_lang].cash_on_gate }} </td>
                        </tr>
                        <!-- row A -->
                         <tr>
                            <td colspan="2" class="border"> {{ print_data[current_lang].reciever }} : {{ voucherData.reciever_name }} </td>
                            <td colspan="4" class="border"> {{ print_data[current_lang].sign }} : </td>
                        </tr>
                        <!-- row A -->
                       <!-- row A -->
                        <tr>
                            <td rowspan="4" colspan="2" class="border baseline"> 
                                {{ print_data[current_lang].address }} : {{ voucherData.reciever_address }}
                                <br>
                                <br>
                                {{ print_data[current_lang].phone }} : {{ voucherData.reciever_phone }} 
                            </td>                            
                            <td rowspan="4" colspan="2" class="border"> {{ print_data[current_lang].yangon_service }}  </td>
                            <td class="border" align="center">
                                {{ voucherData.yangon_service == 0 ? '✔' : '' }}
                            </td>
                            <td class="border"> {{ print_data[current_lang].collect }} </td>
                        </tr>
                        <!-- row A -->
                       <tr>
                            <td class="border" align="center">
                                {{ voucherData.yangon_service == 1 ? '✔' : '' }}
                            </td>
                            <td class="border"> {{ print_data[current_lang].yangon_dtod }} </td>
                        </tr>
                        <!-- row A -->
                        <tr>
                           <td class="border" align="center">
                                {{ voucherData.yangon_service == 2 ? '✔' : '' }}
                            </td>
                            <td class="border"> {{ print_data[current_lang].other_city }} </td>
                        </tr>
                        <!-- row A -->
                        <tr>
                           <td class="border" align="center">
                                {{ voucherData.yangon_service == 3 ? '✔' : '' }}
                            </td>
                            <td class="border"> {{ print_data[current_lang].other_city_dtod }} </td>
                        </tr>
                        <!-- row A -->
                        <tr>
                            <td style="width: 30%" class="border" align="center"> {{ print_data[current_lang].item_name }} </td>
                            <td class="border" align="center" > {{ print_data[current_lang].item_value }} </td>
                            <td class="border" align="center"> {{ print_data[current_lang].count }} </td>
                            <td class="border" align="center" > {{ print_data[current_lang].weight }} </td>
                            <td class="border" align="center"> {{ print_data[current_lang].price_per }} </td>
                            <td class="border" align="center"> {{ print_data[current_lang].price }} </td>
                        </tr>
                        <tr  v-for="(item, index) in voucherData.items" :key="index">
                            <td class="border" align="center" > {{ getItemName(item.name) }} </td>
                            <td class="border" align="center"> {{item.item_price}} </td>
                            <td class="border" align="center" > {{item.item_count}} </td>
                            <td class="border" align="center"> {{item.weight}} </td>
                            <td class="border" align="center"> {{item.price_per_count}} </td>
                            <td class="border" align="center"> {{ getPrice(item).toFixed(0)}} </td>
                        </tr>
                        <tr v-if="voucherData.tax" >
                            <td class="border" align="center"></td>
                            <td class="border" align="center"></td>
                            <td class="border" align="center"></td>
                            <td class="border" align="center"></td>
                            <td class="border" align="center"> 5% </td>
                            <td class="border" align="center"> {{ getTax().toFixed(0) }} </td>
                        </tr>
                        <tr v-if="voucherData.car_fee_val !=0">
                            <td colspan="5" class="border" align="left">
                                <p style="display: inline; margin-left: 30px" v-for="(item, index) in print_data[current_lang].car_fee" :key="index">
                                    {{ voucherData.car_fee == index ? '( ✔ )' : ''}} {{ item }}
                                </p>
                            </td>
                            <td class="border" align="center"> {{ voucherData.car_fee_val }} </td>
                        </tr>
                        <!-- row A -->
                        <tr>
                            <td colspan="3" align="center"> {{ voucherData.remark_2 }} </td>
                            <td colspan="2" class="border" align="right"> {{ print_data[current_lang].total }}  </td>
                            <td class="border" align="center"> NTD  {{getTotal().toFixed(0)}}</td>
                        </tr>
                    </table>
                    <table class="sign-container">
                        <tr>
                            <td align="right">{{ print_data[current_lang].total_sign }}________________________({{ voucherData.createdByLabel }})</td>
                        </tr>
                    </table>
                    <div class="policy-container">
                        <h1 class="policy-header" align="center">
                            {{ print_data[current_lang].rules }}
                        </h1>
                        <ul>
                            <li v-for="(rule, index) in print_data[current_lang].rules_list" :key="index" 
                                :class="{margin_left: !rule.important}"
                            >
                                {{rule.text}}
                            </li>
                        </ul>
                    </div>
                </v-card>
            </v-col>
            <v-col cols="12" md="3" sm="12">
                <v-card
                    class="pa-5"
                    outlined
                    tile
                >
                    <div 
                        class="text-md-subtitle-1 text-subtitle-2 font-weight-bold black--text"
                    > Voucher Info </div>
                    <ul style="list-style-type:none">
                        <li class="black--text text-body-2 my-3">Create Date: {{ voucherData.date }}</li>
                        <li class="black--text text-body-2 my-3">Sender: {{ voucherData.sender_name }}</li>
                        <li class="black--text text-body-2 my-3">Sender Phone: {{ voucherData.sender_phone }}</li>
                        <li class="black--text text-body-2 my-3">Reciever: {{ voucherData.reciever_name }} </li>
                        <li class="black--text text-body-2 my-3">Reciever Phone: {{ voucherData.reciever_phone }}</li>
                        <li class="black--text text-body-2 my-3">Status: <v-chip 
                            dark 
                            :color="voucherData.status == 0 ? 'blue' : 'green' "
                            >
                                {{ voucherData.status == 0 ? 'Shipping' : 'Delivered' }}
                            </v-chip> 
                        </li>
                    </ul>
                </v-card>
                <v-card
                    class="pa-5"
                    outlined
                    tile
                >  
                    <div 
                        class="text-md-subtitle-1 text-subtitle-2 font-weight-bold black--text"
                    > Voucher In </div>
                        <v-radio-group 
                                v-model="current_lang"
                                row
                        >
                            <v-radio
                                v-for="radio_item in languages"
                                :key="radio_item.name"
                                :label="radio_item.name"
                                :value="radio_item.value"
                                class="my-1"
                                color="black"
                            ></v-radio>
                        </v-radio-group>
                </v-card>
                <v-card
                    class="pa-5"
                    outlined
                    tile
                >  
                    <v-btn
                        block
                        x-large
                        depressed
                        light
                        tile
                        color="grey"
                        dark
                        @click="print"
                    >
                        <v-icon>print</v-icon>
                        Print
                    </v-btn>
                    <v-btn
                        class="mt-4"
                        block
                        x-large
                        depressed
                        light
                        tile
                        color="grey darken-2"
                        dark
                        @click="mobile_print"
                    >
                        <v-icon>phone_iphone</v-icon>
                        print mobile
                    </v-btn>
                </v-card>
            </v-col>
        </v-row>
        <!-- alert dialog -->
        <v-dialog
        v-model="alert"
        max-width="300"
        >
        <v-card>
            <v-card-title class="headline red--text">
            Confirm
            </v-card-title>
            <v-card-text>Do you want to delete this voucher ? </v-card-text>
            <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="grey darken-1"
                text
                @click="alert = false"
            >
                Cancel
            </v-btn>
            <v-btn
                color="red darken-1"
                text
                @click="deleteVoucher"
            >
                Delete
            </v-btn>
            </v-card-actions>
        </v-card>
        </v-dialog>
    </div>
</template>
<script>
import {remove, patch} from '../../helper/api';
import voucher_data from '../../helper/voucher_data';

export default {
    name: 'PrintDiv',
    props: {
        voucherData: {
            type: Object,
            required: true
        }
    },
    data () {
        return {
            print_data: voucher_data,
            alert: false,
            form_data: {
                tw_company_name: '金之屋有限公司 Gold Of House Co., Ltd',
                tw_company_contact: 'Taiwan Address: 2-13,Chung Xiao Rd,Chung Ho Tel: 0981 772 578, 0925 038 565',
                mm_company_name: 'Aung Myin Zone Trading Co., Ltd',
                mm_company_contact: 'Yangon Address: No-71,Ou Yin Street,Kyimyindaing Tel: 0925 043 3141, 0978 368 2046'
            },
            current_lang: 'cn',
            languages: [
                {
                    name: 'Burmese',
                    value: 'mm'
                },
                {
                    name: 'Chinese',
                    value: 'cn'
                }
            ],
        }
    },
    methods: {
        deleteVoucher () {
            let vid = this.voucherData._id;
            remove('/vouchers/'+vid).then(()=> {
                this.$router.push('/vouchers');
            }).catch(err => {
                console.log(err);
            })
        },
        print () {
            this.$htmlToPaper('to-print');
        },
        mobile_print() {
            // Element to print
            const element = window.document.getElementById('to-print');
            if (!element) {
                alert(`Element to print #${element} not found!`);
                return;
            }
            window.document.write(`
                <html>
                <head>
                    <title>${window.document.title}</title>
                    <link rel="stylesheet" href="/printstyle.css"></link>
                </head>
                <body>
                    ${element.innerHTML}
                </body>
                </html>
            `);
        },
        getPrice(item) {
            return item.weight * item.price_per_count;
        },
        getItemName(name_arr) {
            if(typeof(name_arr) == "object") {
                let new_name_arr = '';
                name_arr.map((name, index) => {
                    new_name_arr += this.print_data[this.current_lang][name] || name;
                    if(index !== name_arr.length - 1) {
                        new_name_arr += ', '
                    }
                });
                return new_name_arr;
            } else {
                return this.print_data[this.current_lang][name_arr] || name_arr;
            }
            
        },
        getTax() {
            return this.getTotalWithoutTax() * 5/100;
        },
        getTotalWithoutTax() {
            let items = this.voucherData.items;
            let total = 0;
            items.map(item => {
                total += this.getPrice(item);
            });
            return total;
        },
        getTotal () {
            let items = this.voucherData.items;
            let total = 0;
            let tax = 5/100;
            items.map(item => {
                total += this.getPrice(item);
            });
            if(this.voucherData.tax) {
                return total + (total * tax) + parseInt(this.voucherData.car_fee_val);
            }else{
                return total + parseInt(this.voucherData.car_fee_val);
            }
        },
        changeStatus () {
            let raw = {
                status: this.voucherData.status == 0 ? 1 : 0
            }
            patch('/vouchers/'+ this.voucherData._id, raw).then(() => {
                this.voucherData.status = raw.status;
            }).catch(err => {
                console.log('Error: ' + err);
            })
        }
    }
}
</script>
<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville&display=swap');
.print-main-header-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.print-secondary-header-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}
.print-secondary-inner {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.form_header {
    font-family: 'Libre Baskeriville';
    font-size: 26;
    font-weight: 600;
}
.form_header_secondary{
    font-family: 'Libre Baskeriville';
    font-weight: 600;
    font-size: 24,
}
.form_contact {
    font-family: 'Libre Baskeriville';
    font-size: small;
    margin-bottom: 15px;
}
.print-logo {
    width: 100px;
    height: 80px;
    margin: 0 -20px;
}
.date-id-container {
    width: 100%;
    table-layout: fixed;
    margin: 10px 0px;
}
.date-id-container {
    td {
        font-family: 'Libre Baskeriville';
        font-size: small;
    }
}
.main-table {
    width: 100%;
    margin: 10px 0px;
    border-collapse: collapse ;
    border: 1px black solid ;
}
.main-table {
    td {
        font-family: 'Libre Baskeriville';
        font-size: small;
        padding: 7px;
    }
}
.baseline {
    align-items: baseline;
}
.border {
    border: 1px black solid ;
}
.sign-container {
    width: 100%;
    table-layout: fixed;
}
.sign-container {
    td {
        font-family: 'Libre Baskeriville';
        font-size: small;
        padding: 10px;
    }
}
.policy-container {
    margin-top: -20px;
    h1 {
        font-size: medium;
    }
    ul {
        list-style-type: none;
        padding: 0;
        li {
            font-size: small;
            margin: 10px 0px;
        }
    }
}
.margin_left {
    margin-left: 10px !important;
}
</style>