<template>
    <div class="overview-card">
        <v-card
            class="pa-8"
            :color="color"
            dark
            tile
            flat
        >
            <v-row>
                <v-col cols="12" align="center">
                    <h2 class="text-body-1">{{value}}</h2>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" align="center">
                    <h2 class="text-body-2">{{label}}</h2>
                </v-col>
            </v-row>
        </v-card>
    </div>
</template>
<script>
export default {
    name: 'OverviewCard',
    props: {
        icon: {
            type: String,
            required: true
        },
        label: {
            type: String,
            required: true
        },
        value: {
            type: Number,
            required: true
        },
        color: {
            type: String,
            required: true
        }
    }
}
</script>