<template>
    <div class="voucher">
        <v-container>
            <Header :title="'Voucher # ' + voucherData.voucher_id" />
            <print-div :voucherData="voucherData" />
        </v-container>
    </div>
</template>
<script>
import Header from '../components/Header';
import PrintDiv from '../components/Voucher/PrintDiv';
import {get} from '../helper/api';

export default {
    name: 'Voucher',
    components: {
        Header,
        PrintDiv
    },
    data() {
        return {
            id: this.$route.params.id,
            voucherData : {}
        }
    },
    created() {
        get('/vouchers/'+this.id).then(result => {
            this.voucherData = JSON.parse(result);
        });
    }
}
</script>