const menus = [
    {
        title: 'Home',
        key: 'home',
        icon: 'home',
        path: '/',
        role: 0
    },
    {
        title: 'Vouchers',
        key: 'vouchers',
        icon: 'description',
        path: '/vouchers',
        role: 1
    },
    {
        title: 'Add Voucher',
        key: 'add_voucher',
        icon: 'post_add',
        path: '/voucher-add',
        role: 1
    },
    {
        title: 'Report',
        key: 'report',
        icon: 'leaderboard',
        path: '/report',
        role: 0
    },
    {
        title: 'User Roles',
        key: 'user_roles',
        icon: 'groups',
        path: '/user-roles',
        role: 0
    }
];

export default menus;