<template>
    <div class="voucher-items-eidt">
        <v-container>
            <div class="d-flex flex-row justify-space-between py-5 mb-5">
                <div class="font-weight-light text-h6">Toal Items : {{ items.length }}</div>
                <div class="font-weight-bold green--text text-h4">NTD {{ getTotal().toFixed(0) }} </div>
            </div>
            <v-card flat align="right">
                <v-btn
                    depressed
                    light
                    tile
                    color="blue"
                    dark
                    @click="updateVoucher"
                >
                    <v-icon>update</v-icon>
                    Update
                </v-btn>
            </v-card>
            <v-simple-table height="300px" fixed-header >
                <template v-slot:default >
                <thead>
                    <tr>
                    <th v-for="header in headers" class="text-left" :key="header.name">
                        {{header}}
                    </th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                    v-for="(item, index) in items"
                    :key="index"
                    class="text-caption x-small black--text"
                    >
                    <td>{{ item.name }}</td>
                    <td>{{ item.item_price }}</td>
                    <td>{{ item.item_count }}</td>
                    <td>{{ item.weight }}</td>
                    <td>{{ item.price_per_count }}</td>
                    <td>{{ getPrice(item).toFixed(0) }}</td>
                    <td>
                        <v-btn
                            icon
                            small
                            color="black"
                            @click="removeItem(index)"
                        >
                            <v-icon>delete</v-icon>
                        </v-btn>
                    </td>
                    </tr>
                    <tr>
                        <td v-for="(item, index) in form_data" :style=" index == 0 ? 'width: 20%' : ''" :key="index">
                            <v-select
                                v-if="item.type == 'select'"
                                :items="item.data"
                                :placeholder="item.placeholder"
                                v-model="item.value"
                                dense
                                :chips="item.key == 'name'"
                                class="text-body-2"
                                :multiple="item.key == 'name'"
                            >
                                <template v-slot:prepend>
                                    <v-btn
                                        v-if="item.key == 'name'"
                                        icon
                                        @click="manual_name_dialog = true"
                                    >
                                        <v-icon>add</v-icon>
                                    </v-btn>
                                    <v-btn
                                        v-if="item.key == 'price_per_count'"
                                        icon
                                        @click="manual_val_dialog = true"
                                    >
                                        <v-icon>add</v-icon>
                                    </v-btn>
                                </template>
                            </v-select>
                            <v-text-field
                                v-else
                                color="black"
                                dense
                                :placeholder="item.placeholder"
                                v-model="item.value"
                                class="text-body-2"
                            ></v-text-field>
                        </td>
                        <td>
                            <v-btn
                                small
                                block
                                tile
                                flat
                                color="green"
                                dark
                                @click="addItem"
                            >
                                <v-icon>add</v-icon>
                            </v-btn>
                        </td>
                    </tr>
                </tbody>
                </template>
            </v-simple-table>
        </v-container>
        <v-dialog
            v-model="manual_val_dialog"
            width="400"
        >
            <v-card>
                <v-card-title class="subtitle">
                    Enter Value
                </v-card-title>
                <v-card-text>
                        <v-text-field
                            color="black"
                            placeholder="Enter price"
                            v-model="manual_val"
                            class="text-body-2"
                        ></v-text-field>                
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        text
                        color="green"
                        @click="saveManualVal"                 
                    >
                        Save
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog
            v-model="manual_name_dialog"
            width="400"
        >
            <v-card>
                <v-card-title class="subtitle">
                    Enter Item Name
                </v-card-title>
                <v-card-text>
                        <v-text-field
                            color="black"
                            placeholder="Enter price"
                            v-model="manual_name"
                            class="text-body-2"
                        ></v-text-field>                
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        text
                        color="green"
                        @click="saveManualName"                 
                    >
                        Save
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import item_data from '../helper/item_data';
import {get, put} from '../helper/api';

export default {
    name: 'VoucherItemsEdit',
    data () {
        return {
            id: this.$route.params.id,
            voucherData: {},
            selected: [],
            headers: ['name', 'item_price(NTD)', 'item_count', 'weight(kg)', 'price per count(NTD)', 'price(NTD)', ''],
            form_data: item_data,
            manual_val_dialog: false,
            manual_name_dialog: false,
            manual_val: '',
            manual_name: '',
            prices: [60, 65, 70, 80, 100, 150, 200, 1200, 1500]
        }
    },
    created () {
        get('/vouchers/'+this.id).then(result => {
            this.voucherData = JSON.parse(result);
        });
    },
    methods: {
        saveManualVal : function() {
            this.form_data[4].data.push(this.manual_val);
            this.manual_val_dialog = false;
        },
        saveManualName: function() {
            this.form_data[0].data.push(this.manual_name);
            this.manual_name_dialog = false;
        },
        updateVoucher () {
            this.voucherData.total = this.getTotal().toFixed(0);
            this.voucherData.total_weight = this.getToalWeight();
            put('/vouchers/'+this.id, this.voucherData).then(() => {
                this.$router.push('/voucher/'+this.id);
            });
        },
        getPrice(item) {
            return item.weight * item.price_per_count;
        },
        removeItem(index) {
            let items = this.voucherData.items;
            items.splice(index, 1);
            this.voucherData.items = items;
        },
        clearAll() {
            for(let i = 0; i < this.form_data.length ; i++) {
                this.form_data[i].value = '';
            }
        },
        addItem() {
            let item = {};
            this.form_data.map( data => {
                item[data.key] = data.value;
            });
            this.voucherData.items.push(item);
            this.clearAll();
        },
        getTotal () {
            let total = 0;
            let tax = 5/100;
            this.items.map(item => {
                total += this.getPrice(item);
            });
            if(this.voucherData.tax) {
                return total + (total * tax) + parseInt(this.voucherData.car_fee_val);
            } else {
                return total + parseInt(this.voucherData.car_fee_val);
            }
        },
        getToalWeight () {
            let total_weight = 0;
            this.items.map(item => {
                total_weight += parseFloat(item.weight);
            });
            return total_weight;
        }
    },
    computed: {
        items() {
            return this.voucherData.items;
        },
    },
}
</script>