<template>
    <div 
        class="d-flex flex-column align-center pa-10"
    >
        <!-- <div 
            class="text-md-subtitle-1 text-subtitle-2 font-weight-regular black--text"
        > Overview Between</div>
        <div class="d-flex flex-row">
            <v-btn
            small
            rounded
            color="secondary"
            class="ma-1"
            dark
            >
            <v-icon left>
                date_range
            </v-icon>
             {{ new Date().toDateString() }}
            </v-btn>
            <p>to</p>
            <v-btn
                small
                rounded
                color="secondary"
                class="ma-1"
                dark
                >
                <v-icon left>
                    date_range
                </v-icon>
                {{ new Date().toDateString() }}
            </v-btn>
        </div>   -->
    </div>
</template>
<script>
export default {
    name: 'HomeHeader'
}
</script>