<template>
  <div class="home">
  <home-header/>
  <home-container/>
  </div>
</template>

<script>
import HomeHeader from '../components/Home/HomeHeader';
import HomeContainer from '../components/Home/HomeContainer';

export default {
  name: 'Home',
  components:{
    HomeHeader,
    HomeContainer
  },
}
</script>
