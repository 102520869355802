<template>
    <div class="header">
        <!-- <h1
            class="black--text text-h4 my-8 font-weight-light"
        >
            {{ title }}
        </h1> -->
    </div>
</template>
<script>
export default {
    name: 'Header',
    props: {
        title: {
            type: String,
            required: true
        }
    }
}
</script>