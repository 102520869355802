const item_data = [
    {
        name: 'name',
        key: 'name',
        value: '',
        editable: true,
        placeholder: 'Item name',
        type: 'select',
        data: ['書籍', '食品類', '衣服', '家用品', '保養品類', '奶粉', '尿布', '車子相關物品類', '電子相關物品類', '電腦相關物品類', '體積大輕物品', '腳踏車', '藥品類', '玩具', '保健食品', '手錶', '包包', '茶葉', '飲料用品', '粉類物品', '酒', '煙', '牙籤', '香水', '肥皂', '味精', '咖啡', '洗髮精', '沐浴乳', '手機', '印表機和配件']
    },
    {
        name: 'item price (HK$)',
        key: 'item_price',
        value: '',
        editable: true,
        placeholder: 'item price'
    },
    {
        name: 'item count',
        key: 'item_count',
        value: '',
        editable: true,
        placeholder: 'item count'
    },
    {
        name: 'weight (kg)',
        key: 'weight',
        value: '',
        editable: true,
        placeholder: 'weight in kg'
    },
    {
        name: 'price per count(HK$)',
        key: 'price_per_count',
        value: '',
        editable: true,
        placeholder: 'count',
        type: 'select',
        data: [60, 65, 70, 80, 100, 150, 200, 1200, 1500]
    },
]
export default item_data;