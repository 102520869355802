<template>
  <div>
    <v-app-bar-nav-icon dark @click="drawer = !drawer"></v-app-bar-nav-icon>

    <v-navigation-drawer
      v-model="drawer"
      app
      fixed
      light
      temporary
      hide-overlay
    >
      <v-divider></v-divider>
      <v-list nav dense>
        <v-list-item-group>
          <template v-for="(menu, index) in menus">
            <v-list-item v-if="menu.role >= role" :to="menu.path" :key="index" active-class="primary lighten-4">
              <v-list-item-icon>
                <v-icon >{{ menu.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ menu.title }}</v-list-item-title>
            </v-list-item>
          </template>
        </v-list-item-group>
      </v-list>
      <template v-slot:append>
        <v-list-item class="mb-10">
          <v-btn text color="error" @click="alert = true" block>Log Out</v-btn>
        </v-list-item>
      </template>
    </v-navigation-drawer>
    <!-- alert dialog -->
        <v-dialog
        v-model="alert"
        max-width="300"
        >
        <v-card>
            <v-card-title class="headline red--text">
            Confirm
            </v-card-title>
            <v-card-text>Do you want to logout ? </v-card-text>
            <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="grey darken-1"
                text
                @click="alert = false"
            >
                Cancel
            </v-btn>
            <v-btn
                color="red darken-1"
                text
                @click="signOut"
            >
                Logout
            </v-btn>
            </v-card-actions>
        </v-card>
        </v-dialog>
  </div>
</template>

<script>
import { remove } from '../helper/api';

export default {
  name: "NavBar",
  props: {
    menus: {
      type: Array,
      required: true,
    },
  },

  data: () => ({
    drawer: false,
    alert: false
  }),

  methods: {
    signOut() {
      remove('/users/account/logout').then(() => {
        this.$router.push('/login');
      }).catch(err => {
        console.log('Error : ' + err);
      })
    },
  },
  computed: {
    role : function () {
      if(this.$store.state.user) {
        return this.$store.state.user.role;
      } else {
        return 0
      }
    }
  },
};
</script>