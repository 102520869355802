<template>
    <div class="login-card">
        <v-card class="pa-12 mt-10" align="center">
            <v-card-title class="text-sm-subtitle">
                Login
            </v-card-title>
            <v-img
                    width="100"
                    height="auto"
                    src="../../assets/gold of house black.jpg"
            ></v-img>
            <v-card-text class="px-3 py-8">
                <form v-on:submit.prevent="loginUser">
                <v-text-field
                    solo
                    label="email"
                    placeholder="Enter login email"
                    prepend-inner-icon="mail"
                    color="black"
                    v-model="email"
                >
                </v-text-field>
                <v-text-field
                    type="password"
                    solo
                    label="password"
                    placeholder="Enter login email"
                    prepend-inner-icon="lock"
                    color="black"
                    v-model="password"
                >
                </v-text-field>
                <p 
                class="red--text text-caption"
                v-if="error"
                >
                    {{ error_text }}
                </p>
                <div class="d-flex justify-center pt-10">
                    <v-btn
                        large
                        color="blue darken-1"
                        tile
                        dark
                        type="submit"
                    >   
                        <v-icon>login</v-icon>
                        login
                    </v-btn>
                </div>
                </form>
            </v-card-text>
        </v-card>
    </div>
</template>
<script>
import {post} from '../../helper/api';

export default {
    name: 'LoginCard',
    data() {
        return {
            email: '',
            password: '',
            error: false,
            error_text: 'Username or Password wrong !'
        }
    },
    methods: {
        loginUser: function() {
            let body = {
                username: this.email,
                password: this.password
            }
            post('/users/login', body).then(() => {
                this.$router.push('/vouchers');
            }).catch(err => {
                this.error = true;
                console.log('Error: ' + err);
            })
        }
    }
}
</script>