/* This will change according to your server */
// let basePath= 'http://13.212.56.118:8080';

const html_to_paper_options = {
    name: '_blank',
    specs: [
        'fullscreen=yes',
        'titlebar=no',
        'scrollbars=no'
    ],
    styles: [
        `/printstyle.css`
    ]
}

export default html_to_paper_options;