<template>
    <div class="voucher-info-update">
        <v-container>
            <v-card flat align="right">
                <v-btn
                    depressed
                    x-large
                    light
                    tile
                    color="blue"
                    dark
                    @click="updateVoucher"
                >
                    <v-icon>update</v-icon>
                    Update
                </v-btn>
            </v-card>
            <v-row no-gutters>
                <v-col v-for="form_item in header_data"
                            :key="form_item.key" cols="12" md="6">
                    <v-card
                        outlined
                        class="pa-13"
                        tile
                    >
                        <div
                        >
                            <p
                                class="text-md-body-2 text-body-2 font-weight-normal black--text"
                            >
                                    {{ form_item.name }} :
                            </p>
                                <v-text-field
                                    v-if="form_item.type == 'text-field'"
                                    outlined
                                    class="black--text"
                                    v-model="voucherData[form_item.key]"
                                >
                                </v-text-field>
                        </div>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6">
                    <v-card
                        outlined
                        class="pa-13"
                        tile
                    >
                        <div
                            v-for="form_item in sender_data"
                            :key="form_item.key"
                        >
                            <p
                                class="text-md-body-2 text-body-2 font-weight-normal black--text"
                            >
                                    {{ form_item.name }} :
                            </p>
                                <v-text-field
                                    v-if="form_item.type == 'text-field'"
                                    outlined
                                    class="black--text"
                                    v-model="voucherData[form_item.key]"
                                    :prepend-inner-icon="form_item.icon"
                                >
                                </v-text-field>
                                <v-textarea
                                    v-if="form_item.type == 'text-area'"
                                    class="black--text"
                                    outlined
                                    name="input-7-4"
                                    v-model="voucherData[form_item.key]"
                                    :prepend-inner-icon="form_item.icon"
                                ></v-textarea>
                        </div>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6">
                    <v-card
                        outlined
                        class="pa-13"
                        tile
                    >
                        <div
                            v-for="form_item in reciever_data"
                            :key="form_item.key"
                        >
                            <p
                                class="text-md-body-2 text-body-2 font-weight-normal black--text"
                            >
                                    {{ form_item.name }} :
                            </p>
                                <v-text-field
                                    v-if="form_item.type == 'text-field'"
                                    outlined
                                    class="black--text"
                                    v-model="voucherData[form_item.key]"
                                    :prepend-inner-icon="form_item.icon"
                                >
                                </v-text-field>
                                <v-textarea
                                    v-if="form_item.type == 'text-area'"
                                    class="black--text"
                                    outlined
                                    name="input-7-4"
                                    v-model="voucherData[form_item.key]"
                                    :prepend-inner-icon="form_item.icon"
                                ></v-textarea>
                        </div>                    </v-card>
                </v-col>
                <v-col v-for="form_item in remark"
                            :key="form_item.key" cols="12" md="6">
                    <v-card
                        outlined
                        class="pa-13"
                        tile
                    >
                        <div
                        >
                            <p
                                class="text-md-body-2 text-body-2 font-weight-normal black--text"
                            >
                                    {{ form_item.name }} :
                            </p>
                                <v-textarea
                                    v-if="form_item.type == 'text-area'"
                                    outlined
                                    class="black--text"
                                    v-model="voucherData[form_item.key]"
                                >
                                </v-textarea>
                        </div>
                    </v-card>
                </v-col>
                <v-col cols="12">
                    <v-card
                        outlined
                        class="pa-13"
                        tile
                    >
                            <v-checkbox
                            v-model="voucherData.tax"
                            label="Include Tax(5%)"
                            color="green"
                        >
                        </v-checkbox>
                    </v-card>
                </v-col>
                <v-col cols="12">
                    <v-card
                        outlined
                        class="pa-13"
                        tile
                    >
                        <v-row>
                            <v-col cols="4">
                                <div 
                                class="text-md-subtitle-1 text-subtitle-2 font-weight-bold black--text"
                                > {{ payment_methods.name + '(current: '+ voucherData.payment_method_label +')' }} 
                                </div>
                                <v-radio-group 
                                    v-model="voucherData.payment_method"
                                    row
                                >
                                <v-radio
                                    v-for="radio_item in payment_methods.data"
                                    :key="radio_item.name"
                                    :label="radio_item.name"
                                    :value="radio_item.value"
                                    class="my-1"
                                    color="blue"
                                ></v-radio>
                                </v-radio-group>
                            </v-col>
                            <v-col cols="4">
                                <div 
                                class="text-md-subtitle-1 text-subtitle-2 font-weight-bold black--text"
                                > {{ yangon_service.name + '(current : ' + voucherData.yangon_service_label  +')' }} 
                                </div>
                                <v-radio-group 
                                    v-model="voucherData.yangon_service"
                                    row
                                >
                                <v-radio
                                    v-for="radio_item in yangon_service.data"
                                    :key="radio_item.name"
                                    :label="radio_item.name"
                                    :value="radio_item.value"
                                    class="my-1"
                                    color="blue"
                                ></v-radio>
                                </v-radio-group>                            
                            </v-col>
                            <v-col cols="4">
                                <div 
                                class="text-md-subtitle-1 text-subtitle-2 font-weight-bold black--text"
                                > {{ car_fee.name}} 
                                </div>
                                <v-radio-group 
                                    v-model="voucherData.car_fee"
                                    row
                                >
                                <v-radio
                                    v-for="radio_item in car_fee.data"
                                    :key="radio_item.name"
                                    :label="radio_item.name"
                                    :value="radio_item.value"
                                    class="my-1"
                                    color="blue"
                                ></v-radio>
                                </v-radio-group>
                                <v-text-field
                                    v-model="voucherData.car_fee_val"
                                    label="Car Fee"
                                    placeholder="Enter car fee"
                                    color="black"
                                >
                                </v-text-field>                         
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>
<script>
import {get, put} from '../helper/api';

export default {
    name: 'VoucherInfoUpdate',
    data () {
        return {
            id: this.$route.params.id,
            voucherData : {},
            header_data: [
                {
                    name: 'Voucher Id',
                    key: 'voucher_id',
                    type: 'text-field'
                },
                {
                    name: 'Date',
                    key: 'date',
                    type: 'text-field'
                }
            ],
            remark: [
                {
                    name: 'Remark 1',
                    key: 'remark_1',
                    type: 'text-area'
                },
                {
                    name: 'Remark 2',
                    key: 'remark_2',
                    type: 'text-area'
                }
            ],
            sender_data : [
                {
                    name: 'Sender Name',
                    key: 'sender_name',
                    type: 'text-field'
                },
                {
                    name: 'Sender Address',
                    key: 'sender_address',
                    type: 'text-area'
                },
                {
                    name: 'Sender Phone',
                    key: 'sender_phone',
                    type: 'text-field'
                }
            ],
            reciever_data : [
                {
                    name: 'Reciever Name',
                    key: 'reciever_name',
                    type: 'text-field'
                },
                {
                    name: 'Reciever Address',
                    key: 'reciever_address',
                    type: 'text-area'
                },
                {
                    name: 'Reciever Phone',
                    key: 'reciever_phone',
                    type: 'text-field'
                }
            ],
            payment_methods: {
                name: 'Payment Methods',
                key: 'peyment_method',
                data: [
                    {
                        name: '現金支付',
                        value: 0
                    },
                    {
                        name: '銀行轉帳',
                        value: 1,
                    },
                    {
                        name: '貨到付款(在仰光付) ',
                        value: 2,
                    },
                    {
                        name: '送府車費(在仰光付)',
                        value: 3
                    }
                ]
            },
            yangon_service: {
                name: 'Yangon Service',
                key: 'yangon_service',
                data: [
                    {
                        name: '自取',
                        value: 0
                    },
                    {
                        name: '送到府摟下(仰光) ',
                        value: 1,
                    },
                    {
                        name: '送到仰光車站之車費',
                        value: 2,
                    },
                    {
                        name: '送到府(各城市) ',
                        value: 3
                    }
                ]
            },
            car_fee: {
                name: 'Car Fee',
                key: 'car_fee',
                data: [
                    {
                        name: '送到府摟下(仰光)',
                        value: 0
                    },
                    {
                        name: '送到仰光車站之車費',
                        value: 1
                    },
                    {
                        name: ' 送到府(各城市)',
                        value: 2
                    }
                ]
            },
            old_date: ''
        }
    },
    methods: {
        getNewDate(text_date) {
            let date_aryy = text_date.split('.');
            // let new_date = new Date(date_aryy[2], parseInt(date_aryy[1]) -1, date_aryy[0])
            let new_date = new Date(date_aryy[0], date_aryy[1] - 1, date_aryy[2]);
            return new_date.getTime();
        },
        getPrice(item) {
            return item.weight * item.price_per_count;
        },
        getTotal () {
            let total = 0;
            let tax = 5/100;
            this.voucherData.items.map(item => {
                total += this.getPrice(item);
            });
            if(this.voucherData.tax) {
                return total + (total * tax) + parseInt(this.voucherData.car_fee_val);
            } else {
                return total + parseInt(this.voucherData.car_fee_val);
            }
        },
        updateVoucher () {
            if(this.old_date !== this.voucherData.date){
                this.voucherData.createdAt = this.getNewDate(this.voucherData.date);
            }
            this.voucherData.total = this.getTotal().toFixed(0);
            put('/vouchers/'+this.id, this.voucherData).then(() => {
                this.$router.push('/voucher/'+this.id);
            });
        }
    },
    created () {
        get('/vouchers/'+this.id).then(result => {
            this.voucherData = JSON.parse(result);
            this.old_date = this.voucherData.date
        });
    }
}
</script>