<template>
    <div class="login" :style="{ backgroundImage: 'url(' + require('@/assets/login_wallpaper.jpg') + ')' }">
        <v-container>
            <v-row>
                <v-col cols="12" sm="6" align="center">
                </v-col>
                <v-col cols="12" sm="6">
                    <login-card></login-card>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>
<script>
import LoginCard from '../components/Login/LoginCard'

export default {
    name: 'Login',
    components: {
        LoginCard
    }
}
</script>
<style scoped>
.login {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
}
</style>