<template>
    <div class="over-flow-hidden">
        <v-app-bar
            v-if="$route.name !== 'Login'"
            app
            dark
            color="blue"
            src="../assets/wallpaper.jpg"
        >
            <template v-slot:img="{ props }">
                <v-img
                v-bind="props"
                gradient="to top right, #1a3d8477, #1d66b277"
                ></v-img>
            </template>
            <nav-bar :menus="menus" />
            <v-app-bar-title> 
                <div class="d-flex flex-row ml-10">
                    <h4> {{ $route.name }} </h4>
                </div>
            </v-app-bar-title>
        </v-app-bar>
    </div>
</template>
<script>
import NavBar from './NavBar';

export default {
    name: 'AppBar',
    props: {
        menus: {
            required: true,
            type: Array
        }
    },
    components: {
        NavBar
    }
}
</script>