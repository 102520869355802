import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import axios from 'axios';
import VueAxios from 'vue-axios';
import VueHtmlToPaper from 'vue-html-to-paper';
import html_to_paper_options from './helper/html_to_paper_options';
import 'material-design-icons-iconfont/dist/material-design-icons.css' // Ensure you are using css-loader

Vue.config.productionTip = false

Vue.use(VueAxios, axios);

Vue.use(VueHtmlToPaper, html_to_paper_options);

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
