<template>
    <div class="user-card">
        <v-card
            width="300"
            class="my-3"
            v-if="!deleted"
        >
            <v-card-title class="black--text">{{ getRoleLabel }}</v-card-title>
            <v-card-text>
                <p class="subtitle-2">{{ user.full_name }}</p>
                {{ user.email }}
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-btn 
                    text
                    color="red"
                    @click="alert = true"
                >delete</v-btn>
                <v-spacer></v-spacer>
                <v-btn 
                    text
                    color="blue"
                    @click="user.role == 0 ? makeAccoutant() : makeAdmin()"
                > {{ user.role == 0 ? 'Make Accoutant' : 'Make Admin' }} </v-btn>
            </v-card-actions>
        </v-card>
        <!-- alert dialog -->
        <v-dialog
        v-model="alert"
        max-width="300"
        >
        <v-card>
            <v-card-title class="headline red--text">
            Confirm
            </v-card-title>
            <v-card-text>Do you want to delete this user ? </v-card-text>
            <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="grey darken-1"
                text
                @click="alert = false"
            >
                Cancel
            </v-btn>
            <v-btn
                color="red darken-1"
                text
                @click="deleteUser"
            >
                Delete
            </v-btn>
            </v-card-actions>
        </v-card>
        </v-dialog>
    </div>
</template>
<script>
import { put, remove } from '../../helper/api';

export default {
    name: 'UserCard',
    data () {
        return {
            alert: false,
            deleted: false
        }
    },
    props: {
        user: {
            type: Object,
            required: true
        }
    },
    methods : {
        makeAccoutant () {
            let new_data = {
                role: 1
            }
            put('/users/role/'+ this.user._id, new_data).then(() => {
                this.user.role = 1;
            }).catch(err => console.log("Error: " + err));
        },
        makeAdmin () {
            let new_data = {
                role: 0
            }
            put('/users/role/'+ this.user._id, new_data).then(() => {
                this.user.role = 0;
            }).catch(err => console.log("Error: " + err));
        },
        deleteUser () {
            remove('/users/'+ this.user._id).then(() => {
                this.deleted = true;
                this.alert = false;
            }).catch((err => console.log('Error : ' + err)));
        }
    },
    computed : {
        getRoleLabel : function () {
            if(this.user.role) {
                return this.user.role == 0 ? 'Admin' : 'Accoutant'
            }  else {
                return 'Admin'
            }
        }
    }
}
</script>