import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    items: [],
    user: null,
    tax: false
  },
  mutations: {
    addItem(state, item) {
      state.items.push(item);
    },
    removeItem(state, index) {
      state.items.splice(index, 1);
    },
    clearItem(state) {
      state.items = [];
    },
    setUser(state, item) {
      state.user = item;
    },
    setTax(state, data) {
      state.tax = data;
    }
  },
  actions: {
    PUSH_ITEM (context, data) {
      context.commit('addItem', data);
    },
    REMOVE_ITEM (context, data) {
      context.commit('removeItem', data);
    },
    CLEAR_ITEMS (context) {
      context.commit('clearItem');
    },
    SET_USER (context, data) {
      context.commit('setUser', data);
    },
    SET_TAX (context, data) {
      context.commit('setTax', data);
    }
  },
  modules: {
  }
})
