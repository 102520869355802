<template>
    <div class="bar-chart">
        <canvas id="line-chart" height="200"></canvas>
    </div>
</template>
<script>
import Chart from 'chart.js';
let myChart = null;

export default {
    name: 'BarChart',
    props: {
        chart_data: {
            type: Array,
        }
    },
    methods: {
        drawChart: function() {
            let self = this;
            var ctx = document.getElementById('line-chart').getContext('2d');
            myChart = new Chart(ctx, {
                type: 'line',
                data: {
                    labels: this.getLabel,
                    datasets: [{
                        label: '# Money (NTD)',
                        data: self.chart_data,
                        borderColor: 'blue',
                        borderWidth: 1
                    },
                ]
                },
                options: {
                    scales: {
                        yAxes: [{
                            ticks: {
                                beginAtZero: true
                            }
                        }]
                    },
                    elements: {
                        line: {
                            tension: 0.3
                        }
                    }
                }
            });
            myChart.draw();
        }
    },
    created() {
        setTimeout(()=> {
            this.drawChart();
        }, 0);

    },
    computed: {
        getLabel : function() {
            if(this.chart_data) {
                let labels = [];
                this.chart_data.map((data, index) => {
                    labels.push(index);
                });
                return labels;
            } else {
                return []
            }
        }
    },
    watch: {
        chart_data: function() {
            this.drawChart();
        }
    }
}
</script>