<template>
    <div class="vouchers">
        <v-container>
            <Header title="Vouchers" />
            <v-card flat align="right">
                <v-btn
                    depressed
                    light
                    tile
                    color="green"
                    dark
                    @click="$router.push('voucher-add')"
                >
                    <v-icon>add</v-icon>
                    create new voucher
                </v-btn>
            </v-card>
            <vouchers-list></vouchers-list>
        </v-container>
    </div>
</template>
<script>
import VouchersList from '../components/Vouchers/VouchersList';
import Header from '../components/Header';

export default {
    name: 'Vouchers',
    components: {
        VouchersList,
        Header
    }
}
</script>